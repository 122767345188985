<template>
    <div  @wheel.prevent
          @touchmove.prevent
          @scroll.prevent>
        <modal 
            name="SignUpModal" 
            :adaptive="true" 
            :width="getModalWidth()" 
            :height="getModalHeight()"
            @opened="openModal"
            @closed="resetModal"
            class="sd"

            >
        <!-- Main sign up block-->
        <div class="modal" v-if="isSuccess === null">
            <span class="modal__close" @click="hideModal">
              <span
                class="icon-icn_menu_close1"
                @mouseover="hoverClose"
                @mouseleave="hoverClose"
                v-bind:class="{'icon-icn_menu_close1_hover': isHoverClose, 'icon-icn_menu_close1': !isHoverClose}"
              ></span>
            </span>
            <div class="modal__hero">
            <h3 class="modal__title">Sign Up</h3>
            <div class="modal__inputs">
                <div class="input-wrapper">
                  <div class="inputInfo inputName" :class="{inputFullSize: inputFocused.firstName}">First Name</div>
                  <input type="text" name="FirstName" :placeholder="inputFocused.firstName ? '' : 'First Name'"
                  v-model="firstName"
                  :class="{error: !first_name_state}"
                  @focus="inputFocused.firstName = true" @blur="inputFocused.firstName = false"
                  >
                <div v-if="!first_name_state" class="inputInfo validateMessage">{{ (!firstName || firstName.length === 0) ? 'Required' : 'first name is incorrect' }}</div>
                </div>
                <div class="input-wrapper">
                  <div class="inputInfo inputName" :class="{inputFullSize: inputFocused.lastName}">Last Name</div>
                  <input type="text"
                         name="LastName"
                         :placeholder="inputFocused.lastName ? '' : 'Last Name'"
                      v-model="lastName"
                         :class="{error: !last_name_state}"
                      @focus="inputFocused.lastName = true"
                         @blur="inputFocused.lastName = false">
                  <div v-if="!last_name_state" class="inputInfo validateMessage">{{ (!lastName || lastName.length === 0) ? 'Required' : 'last name is incorrect' }}</div>
                </div>
                <div class="input-wrapper">
                  <div class="inputInfo inputName" :class="{inputFullSize: inputFocused.email}">Email</div>
                  <input type="email"
                         name="Email"
                         :placeholder="inputFocused.email ? '' : 'Email'"
                  v-model="email"
                         :class="{error: !mail_state}"
                  @focus="inputFocused.email = true" @blur="inputFocused.email = false">
                  <div v-if="!mail_state" class="inputInfo validateMessage">{{ (!email || email.length === 0) ? 'Required' : 'email is incorrect' }}</div>
                </div>
                <div class="input-wrapper">
                  <div class="inputInfo inputName" :class="{inputFullSize: inputFocused.phone}">Phone</div>
                  <input type="tel"
                         name="Phone"
                         :placeholder="inputFocused.phone ? '' : 'Contact Phone'"
                  v-model="phone"
                         :class="{error: !phone_state}"
                  @focus="inputFocused.phone = true" @blur="inputFocused.phone = false">
                  <div v-if="!phone_state" class="inputInfo validateMessage">{{ (!phone || phone.length === 0) ? 'Required' : 'phone is incorrect' }}</div>
                </div>
            </div>
            <div class="modal__budget">
            <p :class="income_state ? 'income' : 'income-err'" style="margin-bottom: 20px">Your household income</p>
              <Checkbox @getVal="setCh"></Checkbox>
            </div>
            </div>
          <Preloader v-if="is_loading"></Preloader>
            <button class="modal__signUp" @click="signUp" href="#">Sign Up</button>
        </div>
        <!-- Success text block-->
        <div class="modal" v-if="isSuccess">
             <button class="modal__close" @click="hideModal">
                <span
                  class="icon-icn_menu_close1"
                  @mouseover="hoverClose"
                  @mouseleave="hoverClose"
                  v-bind:class="{'icon-icn_menu_close1_hover': isHoverClose, 'icon-icn_menu_close1': !isHoverClose}"
                ></span>
            </button>
            <div class="modal__hero">
                <h3 class="modal__title">Thank you</h3>
                <p class="modal__paragraf">
                  We have received your inquiry.
                  Our lottery leasing team will be in touch with updates and next steps.
                </p>
            </div>
        </div>
        <!-- Error block-->
        <div class="modal" v-if="isDuplicate === true">
          <button class="modal__close" @click="hideModal">
            <span
                class="icon-icn_menu_close1"
                @mouseover="hoverClose"
                @mouseleave="hoverClose"
                v-bind:class="{'icon-icn_menu_close1_hover': isHoverClose, 'icon-icn_menu_close1': !isHoverClose}"
            ></span>
          </button>
          <div class="modal__hero">
            <h3 class="modal__title">Looks like you've already signed up!</h3>
            <p class="modal__paragraf t-center">
               If you need more information, please email
              <a class="faq__contact" href="mailto:email.address@mail.com">info@banksidelottery.com</a>

            </p>
          </div>
        </div>
        <div class="modal" v-else-if="isSuccess === false">
             <button class="modal__close" @click="hideModal">
                <span
                  class="icon-icn_menu_close1"
                  @mouseover="hoverClose"
                  @mouseleave="hoverClose"
                  v-bind:class="{'icon-icn_menu_close1_hover': isHoverClose, 'icon-icn_menu_close1': !isHoverClose}"
                ></span>
            </button>
            <div class="modal__hero">
                <h3 class="modal__title">Ooops</h3>
                <p class="modal__paragraf">
                    It looks like something went wrong.
                    Please, try again later.
                </p>
            </div>
            <button class="modal__signUp" @click="backToSignUp">Sign Up</button>
        </div>
        </modal>
    </div>
</template>

<script>
import Preloader from './Preloader.vue'
import Checkbox from './Checkbox.vue'
//custom images for radio button
import RadioDefauilt from "@/assets/brookfield_web/icn_radio_default.svg"
import RadioCheched from "@/assets/brookfield_web/icn_radio_checked.svg"
import RadioHover from "@/assets/brookfield_web/icn_radio_hover.svg"
import axios from 'axios'
import sbjs from 'sourcebuster';

export default {
  name: 'MainModalForm',
  components: {
    Preloader,
    Checkbox
  },
  data () {
      return {
        //validator data
        firstName: '',
        lastName: '',
        email: '',
        phone: null,
        first_name_state: true,
        last_name_state: true,
        mail_state: true,
        phone_state: true,
        income_state: true,
        disabled_send : false,
        is_loading: false,
        //data for custom radio btns in modal form
        RadioDefauilt, RadioCheched, RadioHover,
        selected_income: null,
        inputFocused: {
          firstName: false,
          lastName: false,
          email: false,
          phone: false
        },
        //is form is success
        isSuccess: null,
        isDuplicate: false,
        isHoverClose: false,
      }
    },
    props: {
        isShow: Boolean, //show/hide the modal form
    },
    watch: {
        isShow: function(){
          if (this.isShow){
              this.$modal.show('SignUpModal');
          }
          else
              this.$modal.hide('SignUpModal');
        },
      firstName(){
          this.first_name_state = true
        },
      lastName(){
          this.last_name_state = true
        },
      email(){
          this.mail_state = true
        },
      phone(){
          this.phone_state = true
        },
    },
  methods: {
    setCh(val){
      this.selected_income = val
    },
    openModal(){
      // document.body.style.position = 'fixed';
    },
    resetModal(){
      // document.body.style.position = '';
      // this.validation.reset()
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phone = null;
      this.first_name_state = true;
      this.last_name_state = true;
      this.mail_state = true;
      this.phone_state= true;
      this.income_state = true;
      this.isSuccess=  null
      this.isDuplicate=  false
      this.is_loading=  false
    },
    showModal () {
      this.$modal.show('SignUpModal');
    },
    hideModal (e) {
      this.$emit('closeModal');
      this.isSuccess = null;
      this.isDuplicate = false;
      e.preventDefault();
    },
    makeDisabled(){
      this.disabled_send = true;
      setTimeout(() => this.disabled_send = false, 10000)
    },
    track(){
      this.$gtag.event('Sign_up', {
        'income_label': this.selected_income
      })
    },
    //sign up btn evnt
   async signUp(){

      if(this.validate() && !this.disabled_send){
        this.makeDisabled()
         this.track()
        this.is_loading = true;
        // await axios.post('https://devmgny02-mgny.cs14.force.com/Billing/services/apexrest/lead/add',
        // ensure stop sending
        await axios.post('',
            {
              'FirstName': this.firstName,
              'LastName': this.lastName,
              'Email': this.email,
              'Phone': this.phone,
              'Income' : this.selected_income,
              'FirstSource' : sbjs.get.first.src + '-' +  sbjs.get.first.mdm + '-' + sbjs.get.first.cmp + '-' + sbjs.get.first.trm,
              'LastSource' : sbjs.get.current.src + '-' +  sbjs.get.current.mdm + '-' + sbjs.get.current.cmp + '-' + sbjs.get.current.trm,
            })
            .then(()=>{
              this.isSuccess = true
            }).catch((error)=>{
              console.log(error);
              console.log(error.response);
              this.isSuccess = false;
              if(error && error.response && error.response.data){
                let obj =  eval('(' + error.response.data + ')');
                if(error && error.response.data && obj.reason === 'duplicate email'){
                  this.isDuplicate = true
                }
              }

              console.log('Error' + error)
            }).finally(()=>{
              this.is_loading = false;
            })
        }
    },

    validate(){
      this.first_name_state = true;
      this.last_name_state = true;
      this.mail_state = true;
      this.phone_state = true;
      this.income_state = true
      let is_valid = true
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


      if(this.firstName === null || this.firstName.length < 2){
        this.first_name_state = false;
        is_valid = false
      }
      if(!this.selected_income){
        this.income_state = false;
        is_valid = false
      }
      if(this.lastName === null || this.lastName.length < 2){
        this.last_name_state = false;
        is_valid = false
      }
      if(this.email === null || this.email.length < 5 || !(re.test(String(this.email).toLowerCase())) ){
        this.mail_state = false;
        is_valid = false
      }
      if(!this.phone || this.phone.length !== 10){
        this.phone_state = false;
        is_valid = false
      }
      return is_valid
    },

    backToSignUp(){
        this.isSuccess = false;
        document.getElementsByClassName('vm--modal')[0].style.height = this.getModalHeight() + 'px';
    },
    //calculate width/height of modal win    
    getModalWidth(){
      if (window.innerWidth < 321)
        return window.innerWidth
      if (window.innerWidth < 391)
        return 358
      if (window.innerWidth < 800)
        return 600;
      return 696; //default
    },
    getModalHeight(){

      if (window.innerWidth < 321 )
        return window.innerHeight
      if (window.innerWidth < 391)
        return window.innerHeight-10

      return 586; //default
    },

    hoverClose() {
        this.isHoverClose = !this.isHoverClose;       
      },
  },

}
</script>
<style>
.modal__radio-group-item{
  justify-content: flex-start!important;
}
</style>
<style>

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
  {
    -webkit-text-fill-color: #FFFFFF !important;
    -webkit-box-shadow: 0 0 0 30px var(--primary-color) inset !important;
  }
</style>
<style scoped>

.vm--modal{
  background: var(--primary-color) !important;
}
.income-err{
  text-decoration-color:#C75356;
  text-decoration-line: underline;
}
.income{
  color:#ffffffdb;
}
.modal{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.modal__close{
  background: transparent;
  border: none;
  cursor: pointer;
  -ms-flex-item-align: end;
      align-self: flex-end;
  padding: 16px 16px 0px;
}
.modal__hero{
  font-family: "Helvetica Neue Regular";
  font-weight: normal;
  padding: 0px 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
          align-items: flex-start;
}
.modal__title{
  color: var(--secondary-color);  
  font-size: 48px;
  line-height: 57px;
  font-weight: normal;
}
.modal__inputs{
  color: #FFFFFF;
  font-size: 20px;
  line-height: 28px;
  padding: 64px 0px 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.input-wrapper{
  position: relative;
}
input,
input::-webkit-input-placeholder {
  font-size: 20px!important;
  line-height: 3;
}
input,::-moz-placeholder {
  font-size: 20px!important;
  line-height: 3;
} /* Firefox 19+ */
input,::-moz-placeholder {
  font-size: 20px!important;
  line-height: 3;
}  /* Firefox 18- */
input,::-ms-input-placeholder {
  font-size: 20px!important;
  line-height: 3;
} /* IE */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
{
  background-color: var(--primary-color) !important;
  border: none;
  border-bottom: 2px solid var(--secondary-hover-color);
  -webkit-text-fill-color: rgba(255,255,255,0.5);
  -webkit-box-shadow: 0 0 0px 0px #000 inset;
  -webkit-transition: background-color 1000s ease-in-out 0s;
  -o-transition: background-color 1000s ease-in-out 0s;
  transition: background-color 1000s ease-in-out 0s;
}
.inputInfo{
  position: absolute;
  font-size: 0px;
  opacity: 0.8;
  -webkit-transition: font-size 0.3s ease-in;
  -o-transition: font-size 0.3s ease-in;
  transition: font-size 0.3s ease-in;
}
.inputFullSize{
  font-size: 12px;
}
.inputName{  
  top: -20px;  
}
.validateMessage{
  bottom: 20px;
  font-size: 12px;
}
.modal__inputs input{
  line-height: 28px;
  min-height: 42px;
  min-width: 275px;
  background: var(--primary-color);
  border: none;
  outline: none;
  border-bottom: 2px solid #fff;
  color: #fff;
  margin-bottom: 48px;
  font-size: 20px;
}
.error{
  border-bottom: 2px solid #C75356 !important;
}
.modal__inputs input:active,
.modal__inputs input:focus{
  border-bottom: 2px solid var(--secondary-hover-color);
}
.modal__inputs input::placeholder{
  color: rgba(255,255,255,0.5);
  font-size: 20px;
  line-height: 28px;
}
.modal__budget{
  color: #FFFFFF;
  font-family: "Helvetica Neue Medium";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
}
.b-txt{
  font-weight: 500;
  margin-bottom: 20px;
}
.modal__radio-group{
  font-family: "Helvetica Neue Regular";
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: normal;
}
#IncomeRadio1, #IncomeRadio2, #IncomeRadio3
{
    width: 20px;
    border: none;
    outline: none;
    height: 20px;
    background: transparent;
    margin-right: 12px;
    cursor: pointer;
    background-image: url(${RadioDefauilt});
}
.modal__radio-group-item{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.modal__radio-group-item label{
  cursor: pointer;
}
.modal__radio-group label{
  margin-right: 40px;
  padding-top: 7px;
}
.modal__signUp{
  position: absolute;
  bottom: 0;
  background: var(--secondary-color);
  color: var(--text-color);
  font-family: "Helvetica Neue Medium";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  padding: 25px 0;
  outline: none;
  border: none;
  min-height: 60px;
  width: 100%;
  margin-top: 40px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.modal__signUp:hover{
  background: var(--secondary-hover-color);
}
.modal__paragraf{
    color: #FFFFFF;
    font-family: "Helvetica Neue Regular";
    font-size: 20px;
    line-height: 28px;
    margin-top: 48px;
    margin-bottom: 20px;
}
.icon-icn_menu_close1:before {
  content: "\e902";
  color: #fff;
  font-size: 20px;
}
.icon-icn_menu_close1_hover:before {
  content: "\e902";
  color: var(--secondary-color);
}
@media screen and (max-width: 1024px) {
    .modal__hero{
        padding: 0px 36px;
      display: block;
      width: 100%;
    }
    .modal__title
    { 
        font-size: 36px;
        line-height: 48px;
    }
    .modal__inputs{
        display: block;
        padding: 36px 0px 28px;
    }
    .modal__inputs input{
        min-width: 100px;
        margin-bottom: 24px;
      width: 100%;
    }
    .modal__budget{
        font-size: 18px;
        line-height: 26px;
    }
    .modal__radio-group label{
        margin-right: 20px;
    }
    .validateMessage{
        bottom: 0px;
    }
}
@media screen and (max-width: 650px) {
    .modal__hero{
        padding: 0px 48px;
    }
    .modal__inputs{
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .modal__title{
      font-size: 32px;
      line-height: 29px;
    }
    .modal__inputs input{
      min-height: auto;
      font-size: 16px;
    }
    .modal__inputs input::placeholder{
      font-size: 16px;
      line-height: 18px;
    }
    .modal__budget{
      font-size: 16px;
    }
    .modal__signUp{
      height: 64px;
    }
}
@media screen and (max-width: 420px) {
  .modal__title{
    padding-top: 16px;
  }
    .modal__hero{
        padding: 0px 16px;
        width: 100%;
    }
    .modal__inputs{
        padding: 20px 0px 0px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
    }
    .input-wrapper{
      width: 100%;
    }
    .modal__inputs input{
        min-width: 100px;
        margin-bottom: 24px;
        width: 100%;
    }
    .inputName{  
        top: -16px;  
    }
    .modal__radio-group{
        display: block;
    }
    .b-txt{
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 28px;
    }
    .modal__radio-group-item{
        margin-bottom: 5px;
      font-size: 20px;
    }
    .modal__signUp{
        margin-top: 20px;
    }
}
@media screen and (max-width: 320px) {
  .modal__title{
    padding-top: 16px;
  }
  .modal__hero{
    padding: 0px 16px;
    width: 100%;
  }
  .modal__inputs{
    padding: 20px 0px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
  .input-wrapper{
    width: 100%;
  }
  .modal__inputs input{
    min-width: 100px;
    margin-bottom: 26px;
    width: 100%;
  }
  .inputName{
    top: -16px;
  }
  .b-txt{
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
  }
  .modal__radio-group{
    display: block;
  }
  .b-txt{
    margin-bottom: 10px;
  }
  .modal__radio-group-item{
    margin-bottom: 5px;
    font-size: 16px;
  }
  .modal__signUp{
    margin-top: 20px;
  }
  input,
  input::-webkit-input-placeholder {
    font-size: 16px!important;
    line-height: 1;
  }
  input,::-moz-placeholder {
    font-size: 16px!important;
    line-height: 1;
  } /* Firefox 19+ */
  input,::-moz-placeholder {
    font-size: 16px!important;
    line-height: 1;
  }  /* Firefox 18- */
  input,::-ms-input-placeholder {
    font-size: 16px!important;
    line-height: 1;
  } /* IE */
}
@media screen and (max-width: 350px) {
    .modal__hero{
        padding: 0px 16px;
    }
}
@media screen and (max-width: 375px) {
    .modal__paragraf{
        margin-top: 28px;
        margin-bottom: 20px;
    }
}
.t-center{
  text-align: center;
  width: 100%;
}
.faq__contact{
  color: var(--secondary-color);
  text-decoration: underline;
  margin-bottom: 12px;
  display: block;
}

</style>