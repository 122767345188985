<template>
  <div id="app">
    <Cookies></Cookies>

    <Header id="Header"
      v-bind:navItems="navItems"
      v-bind:title="`Lincoln at Bankside`"
      v-bind:subtitle="`Beautiful design. Sophisticated homes.`"
      @showModalEvent="showModal"
    />
    <main>
    <TextDescription
        :id="navItems[0]"
        v-bind:header="null"
        v-bind:header_style="`narrow`"
      v-bind:firstText="'Dramatic palettes, thoughtful design, and sophisticated fixtures throughout each apartment.'"
      v-bind:secondText="`Apartments feature floor-to-ceiling windows with views of
Mott Haven, the Harlem River, Queens, and Manhattan.`"
      v-bind:underlinedText="''"
    />
      <Slider  v-bind:img="ApartmentsImages2" />
      <PackageIncludes3  />
      <Building :id="navItems[1]"/>
      <TextDescription
          :id="navItems[2]"
          v-bind:header="`Lincoln at Bankside provides you an immersive oasis.`"
          v-bind:header_style="`wide`"
          v-bind:firstText="`Lincoln’s studio to three-bedroom apartments feature a range of design options. Whether you’re socializing with friends and family,
          enjoying some rest and relaxation, or trying to do a little of both, Lincoln at Bankside has you covered. These are high-end homes to suit your modern, urban life.`"
          v-bind:secondText="``"
          v-bind:underlinedText="`Every apartment in our workforce housing lottery is offered at a steep discount and will be rent-stabilized for 35 years.`"
      />
      <Availibility :id="navItems[3]"/>
      <TextDescription
          :id="navItems[4]"
          v-bind:header="`World-class amenities that rival any in New York City.`"
          v-bind:header_style="`wide`"
          v-bind:firstText="`Lincoln at Bankside offers flexible, functional common spaces and amenities that make room for your whole life: a
          luxe wellness center, resident lounges, versatile coworking space, an indoor lap pool, basketball court, and much more.`"
          v-bind:secondText="``"
          v-bind:underlinedText="``"
      />

    <Slider  v-bind:img="AmenitiesImages" class="pt-40" />
    <PackageIncludes  />

      <TextDescription
          :id="navItems[0]"
          v-bind:header="'Mott Haven is full of the lively small businesses that make communities buzz.'"
          v-bind:header_style="`narrow`"
          v-bind:firstText="` Open, connected, and inspiring: Lincoln at Bankside builds on the energy and connections of its neighbor, Third at Bankside.
      This community is surrounded by cafes, shops, bars, restaurants, and street art.` "
          v-bind:secondText="null"
          v-bind:underlinedText="''"
      />
    <NeighboorsSlider  :id="'slider'" />
    <Map class="mp-d" ></Map>
    <FAQ :id="navItems[5]"/>
    <CallToAction
      v-bind:title="`Join our growing inquiry list!`"
      v-bind:description="'Register here for more information on how to sign up.'"
      @showModalEvent="showModal"
    />
    </main>
    <Footer />
    <ModalForm
      v-bind:isShow="isMainModalShow"
      @closeModal="closeModalEvent"
    />
    <MenuModal
      v-bind:navItems="navItems"
      v-bind:isShow="isMainModalShow"
      @closeModal="closeModalEvent"
    />
  </div>
</template>

<script>
import Cookies from '@/components/Cookies.vue'
import Header from '@/components/Header.vue'
import TextDescription from '@/components/TextDescription.vue'
import NeighboorsSlider from '@/components/NeighboorsSlide2r.vue'
import Map from '@/components/Map2.vue'
import Building from '@/components/Building.vue'
import Availibility from '@/components/Availibility.vue'
import Slider from '@/components/Slider.vue'
import PackageIncludes from '@/components/PackageIncludes.vue'
import PackageIncludes3 from '@/components/PackageIncludes3.vue'
import CallToAction from '@/components/CallToAction.vue'
import FAQ from '@/components/FAQ.vue'

import Footer from '@/components/Footer.vue'
import ModalForm from '@/components/ModalForm.vue'
import MenuModal from '@/components/MenuModal.vue'
import '@/iconstyle.css'

//images from local src need to be imported
//Apartments
import Apartments1 from '@/assets/Apartments/01_Park-min.jpg'
import Apartments2 from '@/assets/Apartments/02_Lobby-min.jpg'
import Apartments3 from '@/assets/Apartments/03_Living Room 1-min.jpg'
import Apartments4 from '@/assets/Apartments/04_Bedroom 1-min.jpg'
import Apartments5 from '@/assets/Apartments/05_Kitchen-min.jpg'
import Apartments6 from '@/assets/Apartments/06_Living Room 2-min.jpg'
import Apartments7 from '@/assets/Apartments/07_Bedroom 2-min.jpg'

//Amenities
import Amenities1 from '@/assets/Amenities/01_Roof Deck-min.jpg'
import Amenities2 from '@/assets/Amenities/02_Fitness 1-min.jpg'
import Amenities3 from '@/assets/Amenities/03_Fitness 2-min.jpg'
import Amenities4 from '@/assets/Amenities/04_Fitness 3-min.jpg'
import Amenities5 from '@/assets/Amenities/05_Coworking-min.jpg'
import Amenities6 from '@/assets/Amenities/06_Lounge Terrarium-min.jpg'
import Amenities7 from '@/assets/Amenities/07_Pool-min.jpg'

import VueMeta from 'vue-meta'
import Vue from 'vue'
Vue.use(require('vue-cookies'))
Vue.use(VueMeta, {
  attribute: 'head'
})

export default {
  name: 'App',
  components: {
    Header, Cookies,
    TextDescription,
    NeighboorsSlider,
    Map,
    Building,
    Availibility,
    Slider,
    PackageIncludes,
    PackageIncludes3,
    CallToAction,
    FAQ,
    MenuModal,
    Footer, ModalForm,
  },
  data () {
      return {
        windowWidth: window.innerWidth,
          //images for sliders components
          ApartmentsImages: [Apartments1, Apartments2, Apartments3, Apartments4, Apartments5, Apartments6, Apartments7 ],
          ApartmentsImages2: [Apartments3, Apartments4, Apartments5, Apartments6, Apartments7 ],
          AmenitiesImages: [Amenities1, Amenities2, Amenities3, Amenities4, Amenities5, Amenities6 , Amenities7],
          //items for navigation
          navItems: [
            'Neighborhood',
            'Incentives',
            'Apartments',
            'Availability',
            'Amenities',
            'FAQ'
          ],
          //to show modal form
          isMainModalShow: false
      }
    },
  methods: {
    showModal () {
      this.$modal.show('SignUpModal');
      this.isMainModalShow = true
    },
    closeModalEvent(){
      this.isMainModalShow = false
      this.$modal.hide('SignUpModal');
    }
  },
  metaInfo() {
    return {
      meta: [
      ]
    }
  },
}
</script>

<style>
.vm--modal{
  border-radius: 0px!important;
}
.mp-d{
  margin-bottom: 60px;
}
@font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('~@/fonts/Helvetica Neue Roman.ttf') format('truetype'),
    url('~@/fonts/Helvetica Neue Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue Bold';
    src: url('~@/fonts/Helvetica Neue Bold.ttf') format('truetype'), 
    url('~@/fonts/Helvetica Neue Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('~@/fonts/Helvetica Neue Medium.ttf') format('truetype'),
    url('~@/fonts/Helvetica Neue Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Icon';
    src: url('~@/fonts/icomoon.eot');
    src: url('~@/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
    url('~@/fonts/icomoon.woff') format('woff'),
    url('~@/fonts/icomoon.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

:root {
  --primary-color: #3b8589;
  --secondary-color: #FFC869;
  --text-color: #1B2023;
  --secondary-hover-color: #F4A010;
}
body {
  color: #1B2023;
}
.mb-0{
  margin-bottom: 0!important;
}
* {
  scroll-behavior: smooth;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1200px;
  margin: auto;
}

button{
  appearance: none;  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.asd{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.pt-40{
  padding-top: 40px;
}
.btn {
  font-family: 'Helvetica Neue Medium';
  font-weight: normal;
  text-align: center; 
  cursor: pointer;  
  padding: 10px auto;
  min-height: 48px;
  background-color: var(--secondary-color);
  border-radius: 24px;
  border: none;  
  color: var(--text-color);
}
.btn:hover{
    background-color: var(--secondary-hover-color);
}
@media screen and (max-width: 1300px) {
    .container {
      width: 94%;
      margin: auto;
    }
}
@media screen and (max-width: 600px) {
    .container {
      width: 92%;
      margin: auto;
    }
}

/*Modal window styles*/
.vm--overlay {
  background:rgba(2,21,22, 0.65) !important;
}
.vm--modal{
  background: var(--primary-color) !important;
}
/*.vm--overlay {*/
/*  background: #566164 !important;*/
/*}*/
.menu-sld{
  transform: translateY(-1260px);

}
.open-mdls{
  transform: translateY(0);
  transition: transform 0.5s;
  -webkit-transition: -webkit-transform 0.5s ease-in;
  transition: -webkit-transform 0.5s ease-in;
  -o-transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3 ease-in, -webkit-transform 0.5s ease-in;
}
</style>
