<template>
    <header class="header"  :class="menuTranslate == 'translateY(-1300px)' ? '' : 'pos-static' ">
      <div class="bg-imgg" ></div>

        <div class="container">
            <div class="menu" >
                <img @click="toTop()" src="@/assets/brookfield_web/logo.svg" class="menu__logo">
                <nav class="nav"
                    v-if="windowWidth > BurgerShowBrakepoint"
                >
                <a
                    v-for="navItem in this.navItems"
                    v-bind:key="navItem"
                    :href="`#${navItem}`"
                    class="nav__item"
                    >{{navItem}}</a>
                </nav>
              <div class="menu__btn"> </div>
<!--                <a class="btn menu__btn"-->
<!--                   @click="showModal"-->
<!--                   v-if="windowWidth > BurgerShowBrakepoint"-->
<!--                ><span class="sign-text">Sign Up</span>-->

<!--                </a>-->
                <div
                    class="burger"
                    @mouseover="hover"
                    @mouseleave="hover"
                    v-if="windowWidth < BurgerShowBrakepoint"
                >
                  <img  v-if="menuTranslate == 'translateY(-1300px)'"  @click="showMenu"  class="" src="@/assets/brookfield_web/menu.svg">
<!--                  <img v-else class="" @click="burgerClick"  src="@/assets/brookfield_web/icn_menu_close.svg">-->
<!--                    <VueCoolBurger                         -->
<!--                        :color=burgerColor-->
<!--                        :cross-color=burgerColor-->
<!--                        id="vueBurger"-->
<!--                    />-->
                </div>
            </div>
          <div class="fixed-menu" v-if="scrollY > 80">
            <div class="fixed-menu-container container">
              <img @click="toTop()" src="@/assets/brookfield_web/logo.svg" class="menu__logo">
              <nav class="nav"  v-if="windowWidth > BurgerShowBrakepoint">
                <a
                    v-for="navItem in this.navItems"
                    v-bind:key="navItem"
                    :href="`#${navItem}`"
                    class="nav__item"
                >{{navItem}}</a>
              </nav>
              <div class=" menu__btn" ></div>
<!--              <div class="btn menu__btn"-->
<!--                   @click="showModal"-->
<!--              >-->
<!--                <span class="sign-text">Sign Up</span>-->
<!--              </div>-->
              <div
                  class="burger"
                  @mouseover="hover"
                  @mouseleave="hover"
                  v-if="windowWidth < BurgerShowBrakepoint"
              >
                <img  v-if="menuTranslate == 'translateY(-1300px)'" @click="showMenu"  class="" src="@/assets/brookfield_web/menu.svg">
                <img v-else class="" @click="burgerClick"  src="@/assets/brookfield_web/icn_menu_close.svg">
                <!--                    <VueCoolBurger                         -->
                <!--                        :color=burgerColor-->
                <!--                        :cross-color=burgerColor-->
                <!--                        id="vueBurger"-->
                <!--                    />-->
              </div>
            </div>

          </div>

            <div class="header__hero">
                <h1 class="header__title">{{title}}</h1>
<!--                <h2 class="header__subtitle1">The application period is now closed.</h2>-->
<!--                <h2 class="header__subtitle1">Be on the lookout for the third and final Bankside housing lottery coming in late 2023.</h2>-->
                <h2 class="header__subtitle1">Applications for our Phase 2 Lottery are opening soon</h2>
                <a class="btn header__btn new-site"
                   @click="gotoNewSite"
                ><span  class="sign-text2">Go to Phase 2 Website</span></a>
<!--               <h2 class="header__soon">NYC Housing lottery application coming soon</h2>-->
            </div>
        </div>
    </header>
</template>

<script>
//bg image for header

//burger
// import VueCoolBurger from "vue-cool-burger";

export default {
    data () {
      return {
        show_menu: false,
        isBurgerHover: false,
        windowWidth: window.innerWidth,
        scrollY: window.scrollY,
        BurgerShowBrakepoint: 900,
        isMobileMenuOpened: false,
        menuTranslate: 'translateY(-1300px)',
        burgerClickCounter: 0, //one click on burger send two events,
      }
    },
    props: {
        title: String,
        subtitle: String,
        navItems: Array
    },
    components: {
        // VueCoolBurger
    },
    methods: {
      gotoNewSite(){
        window.location = 'https://banksidelotteryphase2.com/'
      },
      toTop(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      // scrollEvent(){
      //   console.log(window.scrollY);
      //   if(window.scrollY > 80){
      //     this.show_menu = true
      //   }else{
      //     this.show_menu = false
      //   }
      // },
      showModal () {
        this.$modal.show('SignUpModal');
      },
      showMenu () {
        this.$modal.show('MenuModal');
      },
        hover() {
            this.isBurgerHover = !this.isBurgerHover;
        },
        resizeEvent(){
            this.windowWidth = window.innerWidth;
        },
        scrollYEvent(){
            this.scrollY = window.scrollY;
        },
        burgerClick(){
                if (this.menuTranslate == 'translateY(-1300px)'){
                  this.menuTranslate = 'translateY(0)';
                  document.body.style.position = 'fixed';
                  document.body.style.top = `-${window.scrollY}px`;
                }
                else{
                  this.menuTranslate = 'translateY(-1300px)';
                    //to aviod overflow of menu if it is longer than header
                  // document.body.style.position = '';
                  // document.body.style.top = '';
                    let headerH = document.getElementsByClassName('header')[0].getBoundingClientRect().height;
                    let mobileMenuH = document.getElementsByClassName('mobileMenu')[0].getBoundingClientRect().height;
                    if (headerH > mobileMenuH){
                        document.getElementsByClassName('mobileMenu')[0].style.bottom = '0';
                    }
                    else
                      document.getElementsByClassName('mobileMenu')[0].style.bottom = 'auto';
                }
        },
        closeMobileMenu(){
            document.getElementById('vueBurger').click();
            this.burgerClick();
        },
    },
    computed: {
        burgerColor: function(){
            if (this.isBurgerHover)
                return '#ffffff';
            else
                return '#ffffff'
        }
    },
    created() {
        window.addEventListener("resize", this.resizeEvent);
        window.addEventListener("scroll", this.scrollYEvent);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeEvent);
    },
}
</script>

<style scoped>
.new-site{
  width: 300px!important;
  margin-top: 40px;
}
.bg-imgg{
  background-image: url(../assets/brookfield_web/hero_img.png);
  background-size: cover;
  background-position: center bottom;
  min-height: 100vh;
  width: 100%;
  position: absolute;
  z-index: -1;
}
.header{
  min-height: 100vh;
}
.bg-imgg::before{
  background-color: #1B2023;
  content: '';
  opacity: 0.5;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.sign-text{
  margin-top: 1px;
  height: 28px;
  width: 56px;
  color: #1B2023;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.sign-text2{
  height: 28px;
  color: #1B2023;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
}
.pos-static{
  position: fixed!important;
  z-index: 999;
  top:0
}

.burger{
    height: 22px;
    width: 35px;
}
.vue-cool-burger{
    cursor: pointer;
}
.header{
    background-size: cover;
   background-position: center bottom;
    min-height: 100vh;
    width: 100%;
    position: relative;
  z-index: 2;
}
.header::before{
  background-color: #1B2023;
  content: '';
  opacity: 0.5;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.menu{
    padding: 16px 0px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Helvetica Neue Medium';
    border-bottom: 1px solid rgba(255,255,255,0.4);
    z-index: 10;
    position: relative;
}

.fixed-menu-container{
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.fixed-menu{
  font-family: 'Helvetica Neue Medium';
  border-bottom: 1px solid rgba(255,255,255,0.4);
  z-index: 10;
  padding: 16px 20px;
  position: fixed;
  left: 0;
  top:0;
  width: 100%;
  background: #3B8589;
     border-bottom:none;
}
.menu__logo{
    height: 100%;
    cursor: pointer;
}
.nav__item{
    color: #FFFFFF;
    text-decoration: none;
    margin-left: 35px;
    font-size: 15px;
}
.nav__item:hover{
    color: var(--secondary-hover-color);
}
.menu__btn{
    width: 136px;
    font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
    /*background: #9e8d70;*/
}
.header__hero{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    margin: auto;
    margin-top: 22%;
    width: 90%;
}
.header__title{
    font-family: 'Helvetica Neue Bold';
    color: var(--secondary-color);
    font-size: 56px;
    margin-bottom: 24px;
}
.header__subtitle{
    font-family: 'Helvetica Neue Medium';
    font-weight: 500;
    color: #FFFFFF;
    font-size: 20px;
    margin-bottom: 36px;
    line-height: 28px;
}
.header__subtitle1{
  font-family: 'Helvetica Neue Medium';
  font-weight: 500;
  color: #FFFFFF;
  font-size: 20px;
  margin-bottom: 12px;
  line-height: 28px;
}
.header__soon{
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #FFFFFF;
  font-family: 'Helvetica Neue Regular';
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 20px;
  gap: 10px;
  position: absolute;
  bottom: 32px;
  background: rgba(27, 32, 35, 0.6);
  backdrop-filter: blur(4px);
  border-radius: 23px;
}
.header__btn{
    width: 200px;
    font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-bottom: 126px;
}

#vueBurger, .vue-cool-burger{
    border: none !important;
    outline: none !important;
    appearance: none !important;
}
@media screen and (min-width: 76px) and (max-width: 1100px) {
  .header__subtitle{
    width: 398px;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 28px;
  }
  .fixed-menu{
    padding: 16px 30px 16px;
  }
  .header{
    min-height: 800px;
  }
  .bg-imgg{
    min-height: 800px;
  }
  .header__hero{
    margin-top: 28%;
  }
}
@media screen and (min-width: 601px) and (max-width: 768px) {
  .fixed-menu{
    padding: 16px 18px 16px;
  }
  .header{
    min-height: 800px;
  }
  .header{
    bg-imgg: 800px;
  }
}
@media screen and (max-width: 600px) {
  .fixed-menu{
    padding: 16px 18px 16px;
  }
    .header{
      min-height: calc(100vh - 32px);
    }
  .bg-imgg{
      min-height: calc(100vh - 32px);
    }
    .header__title{
        font-size: 50px;
        margin-bottom: 20px;
    }
    .header__subtitle{
        font-size: 18px;
        margin-bottom: 24px;
        line-height: 26px;
    }
    .burger{
        height: 24px;
        width: 24px;
    }
    .header__hero{
        margin-top: 50%;
    }
    .header{
        background-position: 50%;
    }
    .header__subtitle{
        width: 110%;
    }
    .mobileMenu__item{
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 40px;
    }
    .mobileMenu__wrapper{
        margin-top: 25%;
    }
}
@media screen and (max-width: 420px) {
  .header__soon{
    display: block;
    font-size: 14px;
  }
  .mobileMenu__wrapper{
    margin-top: 0;
  }
}
@media screen and (max-width: 360px) {
  .header__soon{
    display: block;
    font-size: 14px;
  }
    .header__title{
        font-size: 44px;
        margin-bottom: 16px;
    }
    .header__subtitle{
        font-size: 16px;
        line-height: 22px;
    }
    .header__hero{
        margin-top: 121px;
        width: 80%;
    }
    .mobileMenu__item{
        font-size: 36px;
        line-height: 52px;
        margin-bottom: 16px;
    }
}
</style>
