<template>
   <div class="map-wrapper">
     <div class="menu-nav">
       <div @click="current_filter= 'restaurants'" :class="current_filter === 'restaurants' ? 'menu-nav-itm-active' : ''" class="menu-nav-itm">Restaurants & Dining</div>
       <div @click="current_filter= 'parks'" :class="current_filter === 'parks' ? 'menu-nav-itm-active' : ''" class="menu-nav-itm ">Parks</div>
       <div @click="current_filter= 'shop'" :class="current_filter === 'shop' ? 'menu-nav-itm-active' : ''" class="menu-nav-itm">Shopping</div>
       <div @click="current_filter= 'transport'" :class="current_filter === 'transport' ? 'menu-nav-itm-active' : ''" class="menu-nav-itm">Transportation</div>
     </div>
     <div class="zoom-nav">
       <div @click="zoomIn" class="zoom-itm plus-icn"></div>
       <div @click="zoomOut" class="zoom-itm minus-icn"></div>
     </div>
     <MglMap :accessToken="accessToken"
             class="map"
             :center="center"
             :scrollZoom="false"
             :zoom="15"
             @load="onMapLoaded"
             :mapStyle="mapStyle" >
         <MglMarker v-for="(marker, index) in currentFeaturesFiltered"
                    :key="index"
                    :coordinates="marker.coordinates">
           <div class='mrkr' slot='marker'>
             <span class="text-mrkr"></span>
           </div>
           <MglPopup >
             <div class="warp">
                 <img v-if="marker.img" class="pop-img" :src="marker.img" alt="">
               <div class="pop-descr">
                 <div class="m-descr">{{ marker.description }}</div>
                 <a class="gg-map-open" target="_blank" :href="marker.g_coords">Open in Google maps</a>
               </div>
             </div>
           </MglPopup>
         </MglMarker>
       <MglMarker :coordinates="[-73.9304767017124, 40.80787255230509]">
         <div class='mrkr-logo' slot='marker'></div>
       </MglMarker>
     </MglMap>
     <div class="mrkr1"></div>
   </div>
</template>

<script>
import { MglMap, MglPopup, MglMarker  } from "vue-mapbox"
import Metro from '@/assets/ma_pimages/125th Street Metro North.jpg'
import Concourse from '@/assets/ma_pimages/138th Street Grand Concourse.jpg'
import Coffee from '@/assets/ma_pimages/787 Coffee.jpg'
import Beastro from '@/assets/ma_pimages/Beastro.jpg'
import Native from '@/assets/ma_pimages/Bronx Native.jpg'
import Charlies from '@/assets/ma_pimages/Charlies Bar _ Grill.jpg'
import Nobody from '@/assets/ma_pimages/Famous Nobody.jpg'
import Smokehouse from '@/assets/ma_pimages/Hudson Smokehouse.jpg'
import Finca from '@/assets/ma_pimages/La Finca del Sur Community Garden.jpg'
import Puerto from '@/assets/ma_pimages/Made in Puerto Rico.jpg'
import Historic from '@/assets/ma_pimages/Mott Haven Historic District.jpg'
import Pulaski from '@/assets/ma_pimages/Pulaski Park.jpg'
import Bookstore from '@/assets/ma_pimages/The Lit. Bar Bookstore.jpg'
import NYC from '@/assets/ma_pimages/UPNYC.jpg'
import terminal from '@/assets/ma_pimages/terminal.png'
export default {
name: "Map",
  components:{
    MglMap,MglPopup, MglMarker,
  },
  data() {
    return {
      current_filter: 'restaurants',
      map: null,
      center: [-73.92868390363716, 40.80866251247319],
      // accessToken: 'pk.eyJ1IjoiZmlzaGVyYmVyZzEwNTUiLCJhIjoiY2xiemF4bWVlMDdvOTN1bzl0aXd4djJsbiJ9.4rJaLB4Z7Zen-PTYZysnhQ', // МОЯЯ
      accessToken: 'pk.eyJ1IjoibXNwaWRlciIsImEiOiJjbGNoa3hhNHAwMG80M3hueHh6d2hvZHl3In0.7K_1XiSWMcjsRQ5GWPV5gA', // MGNY
      mapStyle: 'mapbox://styles/mapbox/streets-v12' ,
      currentFeatures:[
        {type: 'restaurants', img: Coffee,  description: '787 Coffee', coordinates: [-73.92997919482981, 40.808735921586674], g_coords:'https://www.google.com.ua/maps/place/787+Coffee/@40.8088464,-73.9298217,20z/data=!4m5!3m4!1s0x89c2f55678a7afa1:0xd40e09f212d8d6e7!8m2!3d40.808636!4d-73.9299518' },
        {type: 'restaurants', img: Charlies,  description: 'Charlie’s Bar & Kitchen', coordinates: [-73.929145423251, 40.80804091577417], g_coords:'https://www.google.com.ua/maps/place/Charlies+Bar+%26+Kitchen/@40.8080295,-73.9290549,21z/data=!4m13!1m7!3m6!1s0x0:0x380eeb878d608105!2zNDDCsDQ4JzI5LjAiTiA3M8KwNTUnNDQuOSJX!3b1!8m2!3d40.8080409!4d-73.9291454!3m4!1s0x89c2f5dde84023ad:0x10e1c325ea8fb3f2!8m2!3d40.8078577!4d-73.9292217' },
        {type: 'restaurants', img: Beastro,  description: 'Beastro', coordinates: [-73.92751211371198, 40.80739806623642],g_coords:'https://www.google.com.ua/maps/place/Beatstro/@40.8074324,-73.9275081,21z/data=!4m13!1m7!3m6!1s0x0:0xeb4615a143231fd5!2zNDDCsDQ4JzI2LjYiTiA3M8KwNTUnMzkuMCJX!3b1!8m2!3d40.8073981!4d-73.9275121!3m4!1s0x89c2f5dd9c31016f:0x7861697779579f65!8m2!3d40.807346!4d-73.927521' },
        {type: 'restaurants', img: Smokehouse,  description: 'Hudson Smokehouse', coordinates: [-73.92768961730476, 40.807270395244835], g_coords:'https://www.google.com.ua/maps/place/Hudson+Smokehouse/@40.8072879,-73.9276614,21z/data=!4m13!1m7!3m6!1s0x0:0x9a7aca630e128ff8!2zNDDCsDQ4JzI2LjIiTiA3M8KwNTUnMzkuNyJX!3b1!8m2!3d40.8072704!4d-73.9276896!3m4!1s0x89c2f5467fe143c9:0xf0283351a1b180e3!8m2!3d40.807108!4d-73.9276467'},
        {type: 'restaurants', img: Puerto,  description: 'Made in Puerto Rico Lounge & Bar', coordinates: [-73.92840630817734, 40.80691664479517],g_coords:'https://www.google.com.ua/maps/place/Made+In+Puerto+Rico+Lounge+%26+Bar/@40.8068657,-73.9281873,21z/data=!4m13!1m7!3m6!1s0x0:0x329c23032e28012b!2zNDDCsDQ4JzI0LjkiTiA3M8KwNTUnNDIuMyJX!3b1!8m2!3d40.8069166!4d-73.9284063!3m4!1s0x89c2f5dd8b9aa89f:0xf0249ac76702536e!8m2!3d40.8068497!4d-73.9284044' },
        {type: 'restaurants', img: null,  description: 'Bricks & Hops', coordinates: [-73.92636336956305, 40.806605095004386], g_coords:'https://www.google.com.ua/maps/place/Bricks+%26+Hops/@40.8067155,-73.9267054,21z/data=!4m13!1m7!3m6!1s0x0:0x223183e31efc2b74!2zNDDCsDQ4JzIzLjgiTiA3M8KwNTUnMzQuOSJX!3b1!8m2!3d40.8066051!4d-73.9263634!3m4!1s0x89c2f5c4764c6a95:0x969c4b19977e64cb!8m2!3d40.8065389!4d-73.9263525'},
        {type: 'parks', img: Pulaski,  description: 'Pulaski Park', coordinates: [-73.92440633544092, 40.80525705841433], g_coords:'https://www.google.com.ua/maps/place/Pulaski+Park+Outdoor+Fitness+Equipment/@40.8052284,-73.9244018,20.25z/data=!4m13!1m7!3m6!1s0x0:0x60c804b32adbbc9!2zNDDCsDQ4JzE4LjkiTiA3M8KwNTUnMjcuOSJX!3b1!8m2!3d40.8052571!4d-73.9244063!3m4!1s0x89c2f5e80be004c7:0xee48c73777007089!8m2!3d40.8050177!4d-73.9239469'},
        {type: 'parks', img: null,  description: 'Graham Triangle', coordinates: [-73.92761297312883, 40.810701146077214], g_coords:'https://www.google.com.ua/maps/place/Graham+Triangle/@40.8105336,-73.9276631,21z/data=!4m13!1m7!3m6!1s0x0:0x2b45c98b37af9800!2zNDDCsDQ4JzM4LjUiTiA3M8KwNTUnMzkuNCJX!3b1!8m2!3d40.8107011!4d-73.927613!3m4!1s0x89c2f5dc637facf9:0x2d4758da4198a7ed!8m2!3d40.810555!4d-73.9276237'},
        {type: 'parks', img: Finca,  description: 'La Finca del Sur Community Garden', coordinates: [-73.9304886019639, 40.81282734139322],g_coords:'https://www.google.com.ua/maps/place/La+Finca+del+Sur/@40.8126865,-73.9305842,21z/data=!4m13!1m7!3m6!1s0x0:0x46d058df9e1a605d!2zNDDCsDQ4JzQ2LjIiTiA3M8KwNTUnNDkuOCJX!3b1!8m2!3d40.8128273!4d-73.9304886!3m4!1s0x89c2f5dbe1cff1a7:0xbfd2e4b9c8604bfb!8m2!3d40.8126812!4d-73.9304886' },
        {type: 'parks', img: Historic,  description: 'Mott Haven Historic District', coordinates: [-73.92439807312877, 40.81092872522022],g_coords:'https://www.google.com.ua/maps/place/Mott+Haven+Historic+District/@40.8107841,-73.9242566,20z/data=!4m13!1m7!3m6!1s0x0:0xd1fbb2ca7187906!2zNDDCsDQ4JzM5LjMiTiA3M8KwNTUnMjcuOCJX!3b1!8m2!3d40.8109287!4d-73.9243981!3m4!1s0x89c2f5c4a9ee7c19:0x1074f495d56498a8!8m2!3d40.8107907!4d-73.9244088' },
        {type: 'parks', img: null,  description: 'Harlem River Park', coordinates: [-73.93350357312887, 40.806039603052426],g_coords:'https://www.google.com.ua/maps/place/Harlem+River+Park/@40.8064906,-73.9334206,19z/data=!4m13!1m7!3m6!1s0x0:0xd331518579297bc9!2zNDDCsDQ4JzIxLjciTiA3M8KwNTYnMDAuNiJX!3b1!8m2!3d40.8060396!4d-73.9335036!3m4!1s0x89c2f5e06c55b67d:0x9ff15a4c916a2120!8m2!3d40.8058366!4d-73.9335143' },
        {type: 'parks', img: null,  description: 'Randall’s Island Park', coordinates: [-73.92243414930849, 40.79617327570744], g_coords:'https://www.google.com.ua/maps/place/Randall\'s+Island+Park/@40.7959356,-73.9225279,21z/data=!4m13!1m7!3m6!1s0x0:0xcf7be57bb70f081b!2zNDDCsDQ3JzQ2LjIiTiA3M8KwNTUnMjAuOCJX!3b1!8m2!3d40.7961733!4d-73.9224341!3m4!1s0x89c2f5fad0d08567:0x4ada181916f32de3!8m2!3d40.795813!4d-73.922517'},
        {type: 'shop', img: Bookstore,  description: 'The Lit. Bar Bookstore', coordinates: [-73.92747135533685, 40.80733984576327], g_coords:'https://www.google.com.ua/maps/place/The+Lit.+Bar/@40.8073007,-73.927739,20z/data=!4m13!1m7!3m6!1s0x0:0x6b4e19fe78119848!2zNDDCsDQ4JzI2LjQiTiA3M8KwNTUnMzguOSJX!3b1!8m2!3d40.8073398!4d-73.9274714!3m4!1s0x89c2f57d07780875:0xf805e668ab6c429c!8m2!3d40.8071781!4d-73.9274826'},
        {type: 'shop', img: Native,  description: 'Bronx Native Clothing Store', coordinates: [-73.92950727914898, 40.808104459971446],g_coords:'https://www.google.com.ua/maps/place/Bronx+Native/@40.8081545,-73.9294982,21z/data=!4m13!1m7!3m6!1s0x0:0xabeb2476267aac6e!2zNDDCsDQ4JzI5LjciTiA3M8KwNTUnNDYuMCJX!3b1!8m2!3d40.8082552!4d-73.9294476!3m4!1s0x89c2f5ae8dec9d9d:0xa34913398c08ddbe!8m2!3d40.8080984!4d-73.9294822' },
        {type: 'shop', img: Nobody,  description: 'Famous Nobody’s', coordinates: [-73.9266300092297, 40.80662033020918],  g_coords:'https://www.google.com.ua/maps/place/FAMOUS+NOBODYS/@40.8069944,-73.9266159,20z/data=!4m13!1m7!3m6!1s0x0:0xc9d2b0611af411b8!2zNDDCsDQ4JzI2LjEiTiA3M8KwNTUnMzUuMyJX!3b1!8m2!3d40.8072568!4d-73.9264731!3m4!1s0x89c2f5dd9df51f13:0x1e75006ec081f543!8m2!3d40.8066467!4d-73.9266126'},
        {type: 'shop', img: NYC,  description: 'UP NYC', coordinates: [-73.9283129886112, 40.810022009906376], g_coords:'https://www.google.com.ua/maps/place/UP+NYC/@40.8100068,-73.928478,19z/data=!4m13!1m7!3m6!1s0x0:0x7d66dd66b673c35b!2zNDDCsDQ4JzM2LjEiTiA3M8KwNTUnNDEuOSJX!3b1!8m2!3d40.810022!4d-73.928313!3m4!1s0x89c2f59db97c9e45:0x7834a07e11256665!8m2!3d40.8099405!4d-73.9282891' },
        {type: 'transport', img: Concourse,  description: '138th Street Grand Concourse', coordinates: [-73.92973641205076, 40.81323200608731], g_coords:'https://www.google.com.ua/maps/place/138-%D1%81%D1%82%D1%80%D1%96%D1%82/@40.8133043,-73.929661,21z/data=!4m13!1m7!3m6!1s0x0:0x47a4e083e752ab09!2zNDDCsDQ4JzQ3LjYiTiA3M8KwNTUnNDcuMSJX!3b1!8m2!3d40.813232!4d-73.9297364!3m4!1s0x89c2f5dbdec8a9b5:0x4379827ddbbeb607!8m2!3d40.8132249!4d-73.9298111'},
        {type: 'transport', img: null,  description: '3 Avenue-138 Street Station', coordinates: [-73.92616370955784, 40.810471129446476], g_coords:'https://www.google.com.ua/maps/place/3+Ave%2FE+138+St/@40.8108071,-73.9272272,19z/data=!4m13!1m7!3m6!1s0x0:0x5ac97d76c45212bb!2zNDDCsDQ4JzM3LjciTiA3M8KwNTUnMzQuMiJX!3b1!8m2!3d40.8104711!4d-73.9261637!3m4!1s0x89c2f5dc7fb56b95:0xe4f04a865d5411f2!8m2!3d40.811054!4d-73.927383'},
        {type: 'transport', img: Metro,  description: '125th Street Metro North', coordinates: [-73.93852332887151, 40.80503836313627], g_coords:'https://www.google.com.ua/maps/place/125-%D1%81%D1%82%D1%80%D1%96%D1%82/@40.8045919,-73.9382477,19z/data=!4m13!1m7!3m6!1s0x0:0x5d49d7ebe79a3f87!2zNDDCsDQ4JzE4LjEiTiA3M8KwNTYnMTguNyJX!3b1!8m2!3d40.8050384!4d-73.9385233!3m4!1s0x89c2f60a3f9a668f:0xb30a200e04c74dc!8m2!3d40.8043863!4d-73.9374292'},
        {type: 'transport', img: terminal,  description: 'Grand Central Terminal', coordinates: [-73.97725092675181, 40.75281193651578],g_coords:'https://www.google.com.ua/maps/place/Grand+Central+Terminal/@40.7528942,-73.9772201,19z/data=!3m1!5s0x89c2590220b4c1bf:0x7edf0a3a9c152ffc!4m13!1m7!3m6!1s0x0:0x8aa880d20d9fbb3c!2zNDDCsDQ1JzEwLjEiTiA3M8KwNTgnMzguMSJX!3b1!8m2!3d40.7528119!4d-73.9772509!3m4!1s0x89c259599991bdf9:0xcdb22344c069bda5!8m2!3d40.752714!4d-73.9772269' },
        {type: 'transport', img: null,  description: 'Penn Station', coordinates: [-73.99346535753232, 40.75072240584622], g_coords:'https://www.google.com.ua/maps/place/%D0%9F%D0%B5%D0%BD%D1%81%D0%B8%D0%BB%D1%8C%D0%B2%D0%B0%D0%BD%D1%81%D1%8C%D0%BA%D0%B8%D0%B9+%D0%B2%D0%BE%D0%BA%D0%B7%D0%B0%D0%BB/@40.7504877,-73.993574,19z/data=!4m13!1m7!3m6!1s0x0:0x72d783a9dcb1b547!2zNDDCsDQ1JzAyLjYiTiA3M8KwNTknMzYuNSJX!3b1!8m2!3d40.7507224!4d-73.9934654!3m4!1s0x89c259ae15b2adcb:0x7955420634fd7eba!8m2!3d40.750568!4d-73.993519'},
        {type: 'transport', img: null,  description: 'LaGuardia Airport', coordinates: [-73.87402645529215, 40.777398243852446], g_coords:'https://www.google.com.ua/maps/place/%D0%90%D0%B5%D1%80%D0%BE%D0%BF%D0%BE%D1%80%D1%82+%D0%9B%D0%B0+%D2%90%D0%B2%D0%B0%D1%80%D0%B4%D1%96%D1%8F/@40.7773982,-73.8740265,17z/data=!4m13!1m7!3m6!1s0x0:0x22191c2985fe0467!2zNDDCsDQ2JzM4LjYiTiA3M8KwNTInMjYuNSJX!3b1!8m2!3d40.7773982!4d-73.8740265!3m4!1s0x89c25f8983424db5:0x772fc4660e9666b3!8m2!3d40.7769271!4d-73.8739659'},
      ],
    };
  },
  watch:{
    current_filter: function(newVal){
      if(newVal === 'transport' ){
        this.asyncActions.flyTo({
          center: [-73.93555284679846, 40.781097805500025],
          zoom: (window.innerWidth < 769 && window.innerWidth > 420) ? 11.7 : window.innerWidth < 420 ? 10.5 : 12.3,
          speed: 1
        })
      }
      else if(newVal === 'restaurants' ){
        this.asyncActions.flyTo({
          center: [-73.92878737270262, 40.80786590594716],
          zoom: window.innerWidth < 420 ? 15 : 16.5 ,
          speed: 1
        })
      }
      else if(newVal === 'parks' ){
        this.asyncActions.flyTo({
          center: [-73.9267401119103, 40.80802829449795],
          zoom: (window.innerWidth < 769 && window.innerWidth > 420) ? 13.3 : window.innerWidth < 420 ? 13 :  14.8,
          speed: 1
        })
      }
      else if(newVal === 'shop' ){
        this.asyncActions.flyTo({
          center: [-73.92871662162014, 40.80895401862687],
          zoom: (window.innerWidth < 769 && window.innerWidth > 420) ? 15.7 :window.innerWidth < 420 ? 15 : 16.2,
          speed: 1
        })
      }
    }
  },
  methods:{
    zoomIn(){
      this.asyncActions.zoomIn()
    },
    zoomOut(){
      this.asyncActions.zoomOut()
    },
    async onMapLoaded(event) {
      this.asyncActions = event.component.actions

      this.asyncActions.flyTo({
        zoom: (window.innerWidth < 769 && window.innerWidth > 420) ? 15 : window.innerWidth < 420 ? 14 : 15,
        speed: 1
      })
    }
  },
  computed: {
    currentFeaturesFiltered: function() {
      return this.currentFeatures.filter(itm=>itm.type === this.current_filter)
    }
  },
}
</script>
<style>
@import url("https://api.tiles.mapbox.com/mapbox-gl-js/v0.45.0/mapbox-gl.css");
.mapboxgl-map { position: absolute; top:0; bottom:0; width: 100%; overflow: visible; }
</style>
<style  >
.gg-map-open{
  font-family: 'Helvetica Neue Regular';
  font-size: 14px;
  line-height: 25px;
  text-decoration-line: underline;
  color: #3B8589;
  cursor: pointer;
  margin-top: 5px;
}
.m-descr{
  padding: 10px 2px;
}
.menu-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 32px;
  left: 32px;
  z-index: 1;
  background: rgba(27, 32, 35, 0.8);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  border-radius: 100px;
  width: 447px;
  height:40px;
}
.gg-map-open:focus{
  border: none;
}
.gg-map-open:focus-visible{
  outline: none;
}
.zoom-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 40px;
  right: 16px;
  z-index: 1;
  height:96px;
  flex-direction: column;
}
.zoom-itm{
  background: rgba(27, 32, 35, 0.8);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  border-radius: 100px;
  width: 40px;
  height: 40px;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.plus-icn{
  background-image: url(../assets/plus.png) ;
}
.minus-icn{
  background-image: url(../assets/minus.png) ;
}
.menu-nav-itm{
  font-family: 'Helvetica Neue Regular';
  font-size: 13px;
  line-height: 14px;
  padding: 2px 16px;
  color: #FFFFFF;
  cursor: pointer;
}
.menu-nav-itm-active{
  background: rgba(27, 32, 35, 0.8);
  border-radius: 100px;
  padding: 13px 16px;
  transition: ease-out 0.2s;
}
.map-wrapper{
  position: relative;
  height: 800px;
  width: 100%;
  overflow: hidden;
}
.mapboxgl-popup-content{
  padding:0;
  width: 260px;
  height: auto;
  background: #FFFFFF;
  border-radius: 12px;
}
.mapboxgl-popup-close-button{
  display: none;
}
.warp{
  background: #FFFFFF;
  border-radius: 12px;
}
.pop-img{
  height: 160px;
  width: 260px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: -6px;
}
.pop-descr{
  padding: 4px 12px 12px;
  font-family: 'Helvetica Neue Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1B2023;
  overflow: auto;
}
.mrkr-logo{
  background: url(../assets/logo.png) ;
  background-size: 80px;
  width: 112px;
  height: 80px;
  background-position: top right;
  background-repeat: no-repeat;
}
.text-mrkr{
  position: absolute;
  top:6px;
  left:15px;
  font-family: 'Helvetica Neue Medium';
  font-size: 16px;
  line-height: 28px;
  color: #FFFFFF;
}
.mrkr{
  background: url(../assets/marker.png) ;
  background-size: 40px;
  width: 40px;
  height: 40px;
  z-index: 0;
  background-repeat: no-repeat;
}
.map-container {
  position: relative;
  height: 80vh;
}
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}
</style>
<style>

@media screen and (max-width: 768px) {
  .map-wrapper{
    height: 600px;
  }
  .menu-nav{
    top: 24px;
    left: 24px;
    width: 447px;
    height:34px;
  }
  .menu-nav-itm-active{
    padding: 10px 16px;
  }
  .zoom-nav {
    bottom: 40px;
    right: 10px;
  }

}
@media screen and (max-width: 420px) {
  .map-wrapper{
    height: 520px;
  }
  .menu-nav{
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height:34px;
  }
  .menu-nav-itm{
    font-family: 'Helvetica Neue Regular';
    font-size: 11px;
    line-height: 14px;
    padding: 10px 10px;
    color: #FFFFFF;
    cursor: pointer;
  }
}

</style>