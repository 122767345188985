<template>
    <div class="faq">
        <div class="faq__hero">
            <div class="faq__left">
                <h3 class="faq__title">Frequently Asked Questions</h3>
                <div class="faq__desr-group">
                    <p class="faq__description">If you have more questions, you can always reach out to us.</p>
<!--                    <p class="faq__description">Full list of FAQ <a style="display: inline" class="faq__contact" target="_blank" href="https://spiky-columnist-dc2.notion.site/Lincoln-at-Bankside-Phase-1-FAQs-2597e08fcfd14afd84aa59530c7be353">here</a></p>-->
                    <a class="faq__contact" href="mailto:info@lincolnlottery.com">info@lincolnlottery.com</a>
<!--                    <a class="faq__contact" href="tel:718-689-7489">718-689-7489</a>-->
                    <p class="faq__description fq-small" >Please do not contact or visit the on-site leasing team, as
                      they will only have information about non-lottery apartments.
                      Use the contact listed above for the most accurate and up-to-date lottery information.</p>
                </div>
            </div>
          <p class="faq_wide" >Please do not contact or visit the on-site leasing team, as
            they will only have information about non-lottery apartments.
            Use the contact listed above for the most accurate and up-to-date lottery information.</p>
            <div class="faq__info">
                <FaqItem
                    v-bind:question="`Are the lottery apartments similar to the market-rate apartments?`"
                    v-bind:answer="`Yes, they have the same finishes and similar layouts and are spread throughout the four towers of Lincoln at Bankside.`"
                    v-bind:id="1"
                    v-bind:expandedItem="expandedItem"
                    @setNewExpandItem="setNewExpandItemEvent"
                />
                <FaqItem
                    v-bind:question="`What are the prices of market-rate apartments?`"
                    v-bind:answer="`Unlike the discount rate apartments, the prices for market-rate apartments in Lincoln at Bankside vary from unit to unit.
                    Prices for market rate apartments are generally higher. If you would like more information please get in touch with us and we will connect you
                    with the market rate leasing team.`"
                    v-bind:id="2"
                    v-bind:expandedItem="expandedItem"
                    @setNewExpandItem="setNewExpandItemEvent"
                />
                <FaqItem
                    v-bind:question="`How soon will Lincoln at Bankside be ready for move-in?`"
                    v-bind:answer="`Estimated occupancy for Lincoln at Bankside will begin in Spring/Summer 2023.`"
                    v-bind:id="3"
                    v-bind:expandedItem="expandedItem"
                    @setNewExpandItem="setNewExpandItemEvent"
                />
                <FaqItem
                    v-bind:question="`I would like to tour some of the apartments and amenities. Is it possible to schedule a tour?`"
                    v-bind:answer="`Yes. Opportunities for building and unit tours will be available to those who can provide proof of application submission
                     from Housing Connect prior to the application period close and throughout the eligibility review stage.`"
                    v-bind:id="4"
                    v-bind:expandedItem="expandedItem"
                    @setNewExpandItem="setNewExpandItemEvent"
                />
                <FaqItem
                    v-bind:question="`What are the rents of each apartment? Is it stabilized?`"
                    v-bind:answer="`
                        <div>Studio - $2,525</div>
<!--                        <div>Studio (Foyer) - $ 2,825 </div>-->
                        <div>One-Bedroom - $ 2,825</div>
                        <div>One-Bedroom (Extra Closet/Kitchen Island) - $3,075</div>
                        <div>Two-Bedroom - $3,400</div>
                        <div>Three-Bedroom - $4,373 </div>
                        <div class='mt-2'>The apartments available through the lottery are rent stabilized.</div>`"
                    v-bind:id="5"
                    v-bind:expandedItem="expandedItem"
                    @setNewExpandItem="setNewExpandItemEvent"
                />
              <div class="more">
                <a class="link" href="https://spiky-columnist-dc2.notion.site/Lincoln-at-Bankside-Phase-1-FAQs-2597e08fcfd14afd84aa59530c7be353"
                   target="_blank">Find out more</a>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import FaqItem from '@/components/FaqItem.vue'

export default {
    name: 'FAQ',
    components: {
        FaqItem
    },
    data () {
        return {
            expandedItem: -1
        }
    },
    methods: {
        setNewExpandItemEvent(param){
            this.expandedItem = param.id;
        }
    },
}
</script>
<style>
.mt-2{
  margin-top: 10px
}
</style>
<style scoped>

.more{
  margin-top: 26px;
  height: 28px;
  width: 129px;
  line-height: 28px;
  cursor: pointer;
}
.link{
  color: #1B2023;
  font-family: "Helvetica Neue Medium";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  text-decoration: none;
}
.faq_wide{
  display: none;
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  background-color: var(--secondary-color);
  font-family: "Helvetica Neue Regular";
}
.faq{
    background-color: var(--secondary-color);
    font-family: "Helvetica Neue Regular";
    color: var(--text-color);
}
.faq__hero{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 90px 120px;
}
.faq__left{
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.faq__title{
    font-size: 48px;
    line-height: 56px;
    font-weight: normal;
    margin-bottom: 12px;
}
.faq__desr-group{
    display: block;
    flex-direction: column;
}
.faq__description{
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 12px;
    width: 240px;
}
.faq__contact{
    color: var(--primary-color);
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    text-decoration: underline;
    margin-bottom: 12px;
    display: block;
}
.faq__info{
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
    border-top: 1px solid var(--text-color);
}
@media screen and (max-width: 1100px) {
    .faq__hero{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .faq__left{
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 64px;
    }
    .faq__title{
        margin-bottom: 0px;
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
    }
    .faq__desr-group{
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}
@media screen and (max-width: 768px) {
    .faq__hero{
        padding: 64px 0px;
        width: 90%;
        margin: auto;
    }
    .faq__desr-group{
        -ms-flex-preferred-size: 35%;
        flex-basis: 35%;
    }
    .faq__left{
      margin-bottom: 24px;
    }
  .faq_wide{
    display: block;
    margin-bottom: 64px;
  }
  .fq-small{
    display: none;
  }
}
@media screen and (max-width: 500px) {

    .faq__left{
        flex-basis: 30%;
        flex-direction: column;
        width: 55%;
        margin-bottom: 0;
    }
    .faq__title{
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 12px;
    }
    .faq__desr-group{
        align-self: flex-start;
    }
    .faq__contact:last-child{
        margin-bottom: 0px;
    }
    .faq__description{
        width: 120%;
    }
}
@media screen and (max-width: 350px) {
    .faq__title{
        font-size: 30px;
        line-height: 38px;
    }
    .faq__left{
        width: 70%;
        margin-bottom: 44px;
    }
    .faq__hero{
        padding: 44px 0px 58px;
    }
}
</style>
