<template>
  <div class="slider-wrapper">
    <div class="slider-mask">
      <VueSlickCarousel
          ref="carousel"
          @afterChange="onChangeCarousel"
          :infinite="true"
          :slidesToScroll="1"
          :slidesToShow="3"
          :responsive="responsive"
      >
        <div v-for="(item, index) in sliderContent"
            class="slide"
             :key="index"
        >
          <div :style="{ background: item.bgColor, color: item.textColor }" class="sl_wrap">
            <div class="slide__img" v-bind:style="{ backgroundImage: 'url(' + item.src + ')' }"></div>
            <h3 class="slide__description">{{ item.description }}</h3>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="slider-controls-wrapper">
      <div class="slider-controls-wrapper__progress">
        <div class="progress-bg"></div>
      </div>
      <div class="slider-controls-wrapper__btns">
        <span
          class="icon-icn_arrow_left slider-controls-wrapper__btn"
          @click="showPrev"
          @mouseover="hoverLeft"
          @mouseleave="hoverLeft"
          v-bind:class="{
            'icon-icn_arrow_left_hover': isHoverLeft,
            'icon-icn_arrow_left': !isHoverLeft,
          }"
        ></span>
        <div class="slider-controls-wrapper__text">
          <span class="slider-controls-wrapper__current">{{
            slideNumber
          }}</span>
          of {{ sliderContent.length }}
        </div>
        <span
          class="icon-icn_arrow_right slider-controls-wrapper__btn"
          @click="showNext"
          @mouseover="hoverRight"
          @mouseleave="hoverRight"
          v-bind:class="{
            'icon-icn_arrow_right_hover': isHoverRight,
            'icon-icn_arrow_right': !isHoverRight,
          }"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import slidePic1 from "@/assets/Neighboors/img01-min.jpg";
import slidePic2 from "@/assets/Neighboors/img02-min.jpg";
import slidePic3 from "@/assets/Neighboors/img03-min.jpg";
import slidePic4 from "@/assets/Neighboors/img04-min.jpg";
import slidePic5 from "@/assets/Neighboors/img05-min.jpg";
import slidePic6 from "@/assets/Neighboors/img06-min.jpg";
import slidePic7 from "@/assets/Neighboors/img07-min.jpg";
import slidePic8 from "@/assets/Neighboors/img08-min.jpg";
import slidePic9 from "@/assets/Neighboors/img09-min.jpg";

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "Slider",
  components: {VueSlickCarousel},
  data() {
    return {
      responsive:
        [
            { "breakpoint": 1024, "settings": {"slidesToShow": 2,"slidesToScroll": 1 }    },
            { "breakpoint": 768,"settings": {"slidesToShow": 2,"slidesToScroll": 1, }  },
            {"breakpoint": 480, "settings": {"slidesToShow": 1,"slidesToScroll": 1} }
      ],
      slideNumber: 1,
      isHoverLeft: false,
      isHoverRight: false,
      sliderContentList : [],
      sliderContent: [
        {id: 1,description: "",src: slidePic1,bgColor: "#3B8589",textColor: "#FFC869",alt: "Enterteiment Galore",},
        {id: 2,description: "",src: slidePic2,bgColor: "#FFC869",textColor: "#3B8589",alt: "Bar & Grill", },
        {id: 3,description: "",src: slidePic3,bgColor: "#E86F52",textColor: "#1B2023",alt: "Fitness",},
        {id: 4,description: "",src: slidePic4,bgColor: "#3B8589",textColor: "#FFC869",alt: "Enterteiment Galore", },
        {id: 5,description: "",src: slidePic5,bgColor: "#FFC869",textColor: "#FFC869",alt: "Enterteiment Galore",},
        {id: 6,description: "",src: slidePic6,bgColor: "#E86F52",textColor: "#FFC869",alt: "Enterteiment Galore",},
        {id: 7,description: "",src: slidePic7,bgColor: "#3B8589",textColor: "#FFC869",alt: "Enterteiment Galore",},
        {id: 8,description: "",src: slidePic8,bgColor: "#FFC869",textColor: "#FFC869",alt: "Enterteiment Galore",},
        {id: 9,description: "",src: slidePic9,bgColor: "#E86F52",textColor: "#FFC869",alt: "Enterteiment Galore",},

      ],
      slideHeight: 0,
      windowWidth: 0,
      windowHeight: 0
    };
  },
  methods: {
    showPrev() {
      this.$refs.carousel.prev();
    },
    showNext() {
      this.$refs.carousel.next()
    },
    hoverLeft() {
      this.isHoverLeft = !this.isHoverLeft;
    },
    hoverRight() {
      this.isHoverRight = !this.isHoverRight;
    },
    onChangeCarousel(slideIndex){
      this.slideNumber = slideIndex + 1;
    }
  },
  created() {

  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEvent);
  },
  mounted(){

  }
};
</script>

<style scoped>
.slide__img{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.slide {
  height: 800px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sl_wrap{
  height: 100%;
  width: 100%;
  padding: 24px;
  padding-bottom: 144px;
}
.slider-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-left: 120px;
  padding-bottom: 120px;
  max-width: 1850px;
  margin-left: auto;
  margin-right: auto;
}
.slider-mask{
  width: 1725px;
}
.slider {
  width: 1000%;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: -webkit-transform 0.5s linear;
  transition: -webkit-transform 0.5s linear;
  -o-transition: transform 0.5s linear;
  transition: transform 0.5s linear;
  transition: transform 0.5s linear, -webkit-transform 0.5s linear;
}
.slide-wrapper {
  background-color: #fff;
  width: 4.25%;
  height: 800px;
}
/*.slide-wrapper:first-child {*/
/*  margin-left: 100px;*/
/*}*/

.slid__img {
  /*aspect-ratio: 1.5;*/
}

.slider-controls-wrapper {
  padding: 24px 0px;
  width: 99.35%;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Helvetica Neue Medium";
}
.slider-controls-wrapper__progress {
  -ms-flex-preferred-size: 41%;
  flex-basis: 41%;
  position: relative;
}
.progress-bg {
  background-color: var(--text-color);
  opacity: 0.2;
  width: 100%;
  height: 2px;
}
.progress-active {
  background-color: var(--text-color);
  width: 10%;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: left 0.5s linear;
  -o-transition: left 0.5s linear;
  transition: left 0.5s linear;
}
.slider-controls-wrapper__btns {
  width: 140px;
  color: var(--text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.slider-controls-wrapper__btn {
  cursor: pointer;
  width: 20px;
}
.slider-controls-wrapper__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  color: rgba(27, 32, 35, 0.4);
}
.slider-controls-wrapper__current {
  font-weight: bold;
  margin-right: 5px;
  color: var(--text-color);
}
.slide__description {
  margin-top: 16px;
  font-family: "Helvetica Neue Medium";
  font-size: 34px;
  font-weight: 500;
  line-height: 46px;
}
.icon-icn_arrow_left:before {
  content: "\e900";
  color: #1b2023;
  font-size: 11px;
  display: block;
}
.icon-icn_arrow_left_hover:before {
  content: "\e900";
  color: var(--secondary-color);
  font-size: 11px;
  display: block;
}

.icon-icn_arrow_right:before {
  content: "\e901";
  display: block;
  font-size: 11px;
  color: #1b2023;
}
.icon-icn_arrow_right_hover:before {
  content: "\e901";
  color: var(--secondary-color);
  font-size: 11px;
  display: block;
}

@media screen and (max-width: 1180px) {
  .slider-wrapper {
    padding-left: 3%;
  }
  .slider-mask{
    width: 1500px;
  }
  .slide{
    height: 650px;
  }
  .slide__img{
    height: 550px;
  }

}
@media screen and (max-width: 1024px) {
  .slider-wrapper {
    padding-left: 2%;
  }
  .slider-mask{
    width: 1500px;
  }
  .slider-controls-wrapper__progress {
    -ms-flex-preferred-size: 43%;
    flex-basis: 43%
  }
  .slide__img{
    height: 459px;
  }
  .slide{
    height: 600px;
  }

  .slider-controls-wrapper {
    margin-left: 24px;
    width: 94%;
    z-index: 999;
  }
  .slide {
    padding-bottom: 64px;
  }
}
@media screen and (max-width: 768px) {
  .slide__description {
    font-size: 29px;
  }
  .slide-wrapper {
    width: 6.1%;
    height: 600px;
  }
  .slider-wrapper {
    width: auto;
    padding-left: 14px;
    margin-left: 0;
    padding-bottom: 89px;
  }
  .slider-mask{
    width: 922px;
  }
  .slide__img{
    height: 459px;
  }
  .slide{
    height: 620px;
    padding-bottom: 10px;
  }
  .slide-wrapper:first-child {
    margin-left: 0px;
  }
  .slider-controls-wrapper {
    margin-left: -14px;
    width:  calc(100% + 14px);
    padding: 24px 24px;
  }
  .slider-controls-wrapper__btns {
    width: 100px;
    justify-content: space-between;
  }
  .sl_wrap {
    padding-bottom: 116px;
  }
}

@media screen and (max-width: 420px) {
  .slide__description {
    font-size: 24px;
  }
  .slider-wrapper {
    width: 100%;
    padding-left: 0;
    padding-bottom: 53px;
  }

  .slider-mask{
    width: 100%;
  }
  .slide-wrapper {
    height: 520px;
    width: 9.6%;
  }
  .slide__img{
    height: 385px;
  }
  .slide{
    height: 520px;
    padding: 0 16px 0;

  }

  .slider-controls-wrapper {
    margin-left: 0px;
    width: 100%;
    padding: 24px 16px;
  }
  .slider-controls-wrapper__progress {
    -ms-flex-preferred-size: 70%;
    flex-basis: 70%;
  }
  .slider-controls-wrapper__btn{
    width: 18px
  }
  .slider-controls-wrapper__btns {
    width: 100px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 320px) {
  .slide__description {
    font-size: 24px;
  }
  .slider-wrapper {
    width: 320px;
  }
  .slide-wrapper {
    width: 358px;
    height: 520px;
  }
  .slide__img{
    height: 240px;
  }
  .slide{
    height: 370px;
    padding-bottom: 0;
  }

  .slider-controls-wrapper {
    margin-left: 16px;
  }
  .slider-controls-wrapper__progress {
    -ms-flex-preferred-size: 70%;
    flex-basis: 70%;
  }
}
@media screen and (max-width: 500px) {
  .slider-controls-wrapper__progress {
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
  }
}
@media screen and (max-width: 370px) {
  .slider-controls-wrapper__progress {
    -ms-flex-preferred-size: 55%;
    flex-basis: 55%;
  }
}
/*@media screen and (max-width: 350px) {*/
/*  .slide__description {*/
/*    font-size: 21px;*/
/*  }*/
/*  .slide {*/
/*    padding-bottom: 51px;*/
/*  }*/
/*  .slide-wrapper {*/
/*    width: 9.6%;*/
/*  }*/
/*}*/
</style>
