<template>
    <div class="container building">
        <p class="building__text">
          Live somewhere larger than life with rent stabilized apartments and enjoy the one time incentives
        </p>
      <div>
        <div v-for="(item, index) in tableData" class="itm-wrap"
             :key="index">
          <div class="itm-text">
            <p class="itm-name">{{item.name}}</p>
            <p class="itm-descr">{{item.description}}</p>
          </div>
          <img :src="`${item.img}`" alt="" class="itm-img">
        </div>
      </div>


    </div>
</template>

<script>
import indicies1 from '@/assets/indicies/1_1.jpg'
import indicies2 from '@/assets/indicies/1_2.jpg'
// import indicies3 from '@/assets/indicies/1_3.jpg'
import indicies4 from '@/assets/indicies/1_4.jpg'
import indicies5 from '@/assets/indicies/1_5.jpg'
// import indicies6 from '@/assets/indicies/1_6.jpg'
import indicies7 from '@/assets/indicies/1_7.jpg'
export default {
    data () {
      return {
          tableData: [
            { name: 'Enjoy 1 month free',
              description: 'Yes, that’s right. No rent for 1 month on any standard lease term for our first wave of residents.' ,
              img: indicies1
            },
            { name: 'No security deposit required',
              description: 'The security deposit is waived for the initial lease term. You will only pay the first month’s rent at the time of lease signing.' ,
              img: indicies2
            },
            { name: 'Rent stabilization',
              description: 'For NYC renters, rent stabilized apartments are the most valuable long term investment, ' +
                  'because rent stabilization protects tenants from market rate increases and guarantees the right of renewal. ' +
                  'Your rent will only go up by a very small percentage year over year.' ,
              img:indicies4
            },
            { name: 'Discounted amenity fees',
              description: 'Enjoy our amazing amenities free for one year. See full list below. Some exclusions apply including the pool access.' ,
              img: indicies5
            },
            { name: 'Heat, hot water, cooking gas included in the rent',
              description: 'Residents are only responsible for household electricity. Your heat, hot water, and cooking gas are included in the rent.' ,
              img: indicies7
            },
            // { name: '$500 grocery store credit',
            //   description: 'Third at Bankside also has your back when it comes to groceries. Rent an income-restricted unit with us, and you’ll get a $500 \n' +
            //       'grocery store gift card. Stay healthy and happy.' ,
            //   img: indicies6
            // },
            // { name: 'Heat, hot water, cooking gas included in rent',
            //   description: 'Tenants are only responsible for electricity. Your heat, hot water, and gas are all included in rent. ' ,
            //   img: indicies3
            // },
          ],
      }
    },
}
</script>

<style scoped>

.itm-text{
  display: flex;
  flex-direction: column;
  align-self: center;
}
.itm-name{
  color: #1B2023;
  font-family: "Helvetica Neue Medium";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 4px;
}
.itm-descr{
  color: #1B2023;
  font-family: "Helvetica Neue Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 25px;
  width: 793px;;
}

.itm-img{
  height: 71.96px;
  width: 71.51px;
}
.itm-wrap{
  padding: 32px 0;
  border-top: 1px solid #1B2023;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.math{
  font-family: "Helvetica Neue Medium";
  text-decoration: underline;
}
.building{
    font-family: 'Helvetica Neue Regular';
    padding: 141px 100px 68px;
}
.building__text{
    font-size: 34px;
    margin-bottom: 80px;
    line-height: 48px;
}
table{
    border-collapse: collapse;
    font-family: 'Helvetica Neue Regular';
    margin-top: 48px;
    margin-left: auto;
}
tr{
    height: 32px;
}
th{
    border-bottom: 1px solid var(--text-color);
    width: 345px;
    font-size: 20px;
    line-height: 32px;
    font-weight: normal;
    padding: 16px 0px;
}building
th:first-child{
    text-align: left;
}
th:last-child{
    text-align: right;
    font-family: 'Helvetica Neue Medium';
}
tr:last-child th{
    border-bottom: none;
}
@media screen and (max-width: 1100px) {
    .building{
        padding: 64px 0px;
    }
    .itm-name{
     width: 550px;
    }
  .itm-descr{
     width: 550px;
    height: auto;
    }
    .building__text{
      width: auto;
      font-size: 29px;
      letter-spacing: 0;
      line-height: 39px;
      margin-bottom: 64px;
    }
    th{
        width: 260px;
    }
}
@media screen and (max-width: 768px) {
    .building{
      padding: 100px 0 0;
    }
    .building__text{
      width: auto;
      font-size: 29px;
      letter-spacing: 0;
      line-height: 39px;
      margin-bottom: 64px;
    }
    .itm-wrap:last-of-type{
      padding-bottom: 0;
    }
}
@media screen and (max-width: 600px) {
  .itm-img{
    margin-bottom: 16px;
    height: 64px;
    width: 64px;
    align-self: flex-start;
  }
  .itm-wrap{
    padding: 24px 0;
    flex-direction: column-reverse;
  }
  .itm-name{
    height: auto;
    width: auto;
    font-size: 18px;
    line-height: 32px;
  }
  .itm-descr{
    height: auto;
    width: auto;
    font-size: 14px;
    line-height: 25px;
  }
    .building{
        padding: 0px 0 10px 0px;
    }
    .building__text{
      font-size: 24px;
      line-height: 31px;
      width: auto;
      margin-bottom: 56px;
    }
    th{
        width: 90%;
        font-size: 18px;
    }
    th:last-child{
        min-width: 150px;
    }
}
@media screen and (max-width: 350px) {
    .building{
        padding: 56px 0px;
    }
    .building__text{
        font-size: 21px;
        line-height: 29px;
    }
    th{
        width: 90%;
        font-size: 16px;
    }
    th:last-child{
        min-width: 120px;
    }
}
</style>
